
import { Component, OnInit, ChangeDetectionStrategy, HostListener, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { KEY_CODE } from 'src/app/models/enums/key-codes.enum';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { BrokerGroup } from 'src/app/models/broker-group';
import { Utility } from 'src/app/utils/utility';


@Component({
  selector: 'app-search-broker-group',
  templateUrl: './search-broker-group.component.html',
  styleUrls: ['./search-broker-group.component.scss']
})
export class SearchBrokerGroupComponent implements OnInit, AfterViewInit {
  results: BrokerGroup[] = [];

  searchTerm$ = new Subject<string>();
  searchTerm: string = '';
  displayedColumns: string[] = ['Name'];

  showResult = false;
  noResult: boolean = false;
  index = -1;
  searchPageSize = 1000;

  @Input() isBrokerGroup = false;
  @Input() isUpdateBrokerGroup = false;

  selectedBrokerGroup: BrokerGroup;
  @Output() selectedBrokerGroupOutput = new EventEmitter<BrokerGroup>();

  private wasInside = false;

  viewResultInFlight$ = new BehaviorSubject<boolean>(false);

  form: FormGroup;

  constructor(private readonly _httpService: HttpBaseService, private readonly _api: ApiEndPoints, private readonly formBuilder: FormBuilder, ) {
    this.createForm();
  }

  get searchControl() {
    return this.form.get('searchControl') as AbstractControl;
  }

  get searchStringIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.searchControl.value);
  }

  ngOnInit() {

  }

  createForm() {
    this.form = this.formBuilder.group({
      searchControl: [undefined]
    });
  }

  ngAfterViewInit(): void {
    if (this.searchControl) {
      this.searchControl.valueChanges.subscribe(x => {
        if (!x) {
          this.closeSearchResult();
        }
      });
    }
  }

  closeSearchResult() {
    this.index = -1;
    this.results = [];
    this.showResult = false;
    this.noResult = false;
  }

  search(query): void {
    this.viewResultInFlight$.next(true);
    this.closeSearchResult();
    this._httpService.getData(this._api.searchBrokerGroup(query.trim()))
      .pipe(
        map(response => ({
          results: response,
        }))
      )
      .subscribe(results => {
        if (results.results == null || results.results == 0) {
          this.noResult = true;
          this.showResult = false;
        } else {
          this.viewResultInFlight$.next(false);
          this.showResult = true;
          this.results = results.results as BrokerGroup[];
        }
      });
  }

  reset() {
    this.searchControl.setValue('');
    this.noResult = false;
  }

  onRowClick(brokerGroup: BrokerGroup) {
    this.selectedBrokerGroup = brokerGroup;
    this.selectedBrokerGroupOutput.emit(this.selectedBrokerGroup);
    this.closeSearchResult();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW || event.keyCode === KEY_CODE.DOWN_ARROW) {
      this.increment();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW || event.keyCode === KEY_CODE.UP_ARROW) {
      this.decrement();
    }

    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.closeSearchResult();
    }

    if (event.keyCode === KEY_CODE.ENTER) {
      let index = this.index;
      if (this.showResult && index >= 0 && index < this.results.length) {

        this.selectedBrokerGroup = this.results[this.index];
        this.selectedBrokerGroupOutput.emit(this.selectedBrokerGroup);
        this.closeSearchResult();

      }
    }
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.closeSearchResult();
    }
    this.wasInside = false;
  }

  increment() {
    if (this.index < this.results.length - 1) {
      this.index++;
    }
    this.selectedBrokerGroup = this.results[this.index];
  }

  decrement() {
    if (this.index > 0) {
      this.index--;
    }

    this.selectedBrokerGroup = this.results[this.index];
  }
}
