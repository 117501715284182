import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { BROKER_ID, PagingConstant, NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { FormBuilder, AbstractControl } from '@angular/forms';

import { UserDataSource } from 'src/app/models/user.datasource';
import { UserService } from 'src/app/services/user.service';
import { Role, UserDetail } from 'src/app/models/user.model';
import { Utility } from 'src/app/utils/utility';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PreviousRouteService } from 'src/app/shared/services/previous-route.service';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';
import { ResendOptionPopupComponent } from 'src/app/shared/components/resend-option-popup/resend-option-popup.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, AfterViewInit {

  brokerId = BROKER_ID;
  displayedColumns: string[] = ['userName', 'role', 'brokerBranch', 'phoneNumber', 'emailAddress', 'isActive', 'actions', 'resend'];

  dataSource: UserDataSource;
  totalRecords: number;
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  searchString: string;

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });

  filterForm = this.formBuilder.group({
    roleId: ['']
  });

  roles: Role[] = [];
  notfound: string = '';

  get notFoundMessage() {
    return this.notfound;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _userService: UserService,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly dialogService: DialogService,
    private _previousRouteService: PreviousRouteService) {

  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  get roleId() {
    return this.filterForm.get('roleId') as AbstractControl;
  }

  async ngOnInit() {
    if (this._previousRouteService.getPreviousUrl().includes("users/user/")) {
      this.searchString = this._userService.getSearchStringUserList();
    }
    this.dataSource = new UserDataSource(this._userService);

    await this.loadData();
    this.sharedSearch.setSearchString(this.searchString);
    this.dataSource.usersSubject$.subscribe(u => {
      this.checkNotFoundResults();
    });
    await this.getRoles();
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  async loadData(sortField: string = 'UserName', sortDirection: string =  'asc') {
    let index = this.paginator ? this.paginator.pageIndex : 0;
    let pageSize = this.paginator ? this.paginator.pageSize : PagingConstant.pageSizeLarger;
    this.dataSource.getUsers(this.searchString, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
    this._userService.setSearchStringUserList(this.searchString);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
  }

  async loadByRole(roleId: string, sortField: string = 'UserName', sortDirection: string =  'asc') {
    let index = this.paginator ? this.paginator.pageIndex : 0;
    let pageSize = this.paginator ? this.paginator.pageSize : PagingConstant.pageSizeLarger;
    this.dataSource.getUsersByRole(roleId, this.searchString, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
  }

  extract() {
    let url = this._userService.extractKeyContactUsers();
    this._httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = "KeyContactUsers " + new Date().toLocaleDateString("en-UK");
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);

      }
    );
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getUsersPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getUsersPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.processLoadData(this.roleId.value);
    });

    this.roleId.valueChanges.subscribe(async (id: string) => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      if (id) {
        this.loadByRole(id);
      } else {
        this.loadData();
      }
    });
  }

  async getUsersPage() {
    let fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    let direction = this.sort.direction;

    if (this.roleId.value) {
      this.loadByRole(this.roleId.value, fieldSort, direction);
    }
    else {
      this.loadData(fieldSort, direction);
    }
  }

  createUser() {
    this.router.navigate([`/app/users/create`], { relativeTo: this.activatedRoute });
  }

  editUser(user: UserDetail) {
    this.router.navigate([`/app/users/user/${user.id}`], { relativeTo: this.activatedRoute });
  }

  async resendRegistration(user: UserDetail) {
    this.dialogService.openComponentDialog(ResendOptionPopupComponent, undefined, { width: '700px' }
    ).subscribe(async (result: any) => {
      if (result) {
        user.administratorEmail = result.email?.trim();
        await this.doResendRegistration(user);
      }
    });
  }

  async doResendRegistration(user: UserDetail) {
    let result = await this._httpService.putDataAsync<string>(this._api.getUserById(user.id), user);
    if (result) {
      let data = {
        type: 1,
        title: 'Success',
        message: 'Registration successfully sent.'
      };
      this.dialogService.openSuccessDialog(data);
    }
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    await this.processLoadData(this.roleId.value);
  }

  async processLoadData(roleId: string) {
    if (roleId) {
      await this.loadByRole(roleId);
    }
    else {
      await this.loadData();
    }
    this.checkNotFoundResults();
  }

  async getRoles() {
    this.roles = await this._httpService.getDataAsync<Role[]>(this._api.getSystemRoles());
  }

  formatPhoneNumber(phonenumber) {
    phonenumber = phonenumber.split(' ').join("");
    const formatted = Utility.formatPhoneNumber(phonenumber);
    return formatted;
  }
}
