<mat-card appearance="outlined" [formGroup]="brokerForm">
  <mat-card-header>
    <div class="flex-60">
      <mat-card-title><strong>Broker Details</strong></mat-card-title>
      <mat-card-subtitle>{{updateMode?'Update': 'Create'}} your broker here.
      </mat-card-subtitle>
    </div>
    <div class="contact-details">
      <mat-card-title><strong>Contact Details</strong></mat-card-title>
      <mat-card-subtitle>Enter the Broker Contact details here (Optional).</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content class="broker-detail-container">
    <div class="display-flex flex-direction-row">
      <div class="flex-60">
        <section>
          <mat-form-field class="white-form-field">
            <mat-label>Broker Name</mat-label>
            <input matInput data-test="brokerNameInput" placeholder="Broker Name" formControlName="brokerName"
              [required]="true" TitleCase>
            <mat-error *ngIf="brokerName.errors?.required || brokerName.errors?.whitespace">Broker Name is
              required</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Broker ID</mat-label>
            <input matInput data-test="brokerIdInput" numberOnly placeholder="Broker ID" formControlName="brokerId"
              numberOnly [required]="true" [readonly]='updateMode' maxlength="9">
            <mat-error *ngIf="brokerId.value && brokerId.errors?.specialCharacter">
              {{brokerId.errors?.specialCharacter?.message}}</mat-error>
            <mat-error *ngIf="brokerId.invalid && !brokerId.value && !brokerId.errors?.specialCharacter">Broker Id is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="select-type">
            <mat-label>Select status from dropdown</mat-label>
            <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status" required>
              <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
                {{status}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="status.invalid">Status is required</mat-error>
          </mat-form-field>
        </section>

        <section>
          <mat-form-field>
            <mat-label>Select External Type from dropdown list</mat-label>
            <mat-select data-test="externalType" placeholder="Select External Type from dropdown list" formControlName="externalType">
              <mat-option [value]="emptyExternalType">N/A</mat-option>
              <mat-option *ngFor="let type of ExternalBrokerType.values()" [value]="ExternalBrokerType[type]">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>External Reference</mat-label>
            <input matInput data-test="externalReference" placeholder="External Reference" formControlName="externalReference">
             <mat-error *ngIf="externalReference?.hasError('specialCharacter') && !externalReference?.hasError('maxlength')">{{externalReference.errors?.specialCharacter?.message}}</mat-error>
             <mat-error *ngIf="externalReference?.hasError('maxlength')">{{externalReferenceMaxLenghtMessage}}</mat-error>
            </mat-form-field>
        </section>

        <app-address-info [addressForm]="brokerAddress" [required]="true">
        </app-address-info>

        <section>
          <mat-form-field>
            <mat-label>Telephone</mat-label>
            <input [required]="true" spellcheck="false" data-test="phonenumberInput" matInput placeholder="Telephone"
              formControlName="phonenumber" type="tel">
            <mat-error *ngIf="phonenumber.errors && phonenumber.errors.required">Telephone number is
              required</mat-error>
            <mat-error *ngIf="phonenumber.errors && phonenumber.errors.invalidPhoneNumber">Invalid phone number, please
              re-enter</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input matInput data-test="emailInput" placeholder="Email Address" formControlName="email"
              [required]="true" noWhiteSpaceInput>
            <mat-error *ngIf="email.errors && email.errors.email || email.hasError('invalidEmail')">Invalid Email,
              please re-enter</mat-error>
            <mat-error *ngIf="email.errors && email.errors.required">Email is required</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Website</mat-label>
            <input matInput data-test="websiteInput" placeholder="Website" formControlName="website">
            <mat-error *ngIf="website?.errors?.urlFormat">Invalid url format entered. Please retry.</mat-error>
          </mat-form-field>

        </section>
        <section class="display-flex">
          <div class="broker-logo" *ngIf="brokerLogoUrl">
            <div>
              <span class="broker-logo-text">Broker Logo</span>
            </div>
            <div class="broker-logo-file">
              <a href={{brokerLogoUrl}} target="_blank">{{getFileName(brokerLogoUrl)}}</a>
            </div>
          </div>

          <mat-form-field class="select-file">
            <mat-label>Broker Logo File</mat-label>
            <input data-test="brokerLogo" placeholder="Broker Logo File" matInput type="search" readonly
              formControlName="logo">
            <mat-icon class="search-button" matSuffix svgIcon="upload" (click)="file.click()" matTooltip="Upload"></mat-icon>
          </mat-form-field>
          <input #file type="file" [hidden]="true" accept="image/*" (change)="onSelectFile($event.target.files)">
        </section>

        <section class="check-branch">
          <mat-checkbox class="check-box" formControlName="isBranchDetail">Branch details are
            the same as Broker
          </mat-checkbox>
        </section>

      </div>
      <div class="flex-40">
        <app-contact-detail #contactDetailFormComponent1>
        </app-contact-detail>
        <app-contact-detail #contactDetailFormComponent2>
        </app-contact-detail>
      </div>
    </div>

    <div class="justify-items-end button-row">
      <button class="btn" mat-button mat-raised-button color="primary" [disabled]="isInvaliditySave()" (click)="onSave()">
        {{updateMode?'Update': 'Save'}} </button>
      <button class="btn" mat-button mat-raised-button color="primary" [disabled]="isInvaliditySaveAndClose()"
        (click)="onSaveAndClose()">{{updateMode?'Update & close': 'Save & close'}}</button>
      <button class="btn" mat-button mat-raised-button color="secondary" (click)="onCancel()">Cancel</button>
    </div>
  </mat-card-content>

  <section *ngIf="currentBroker">
    <app-branches [broker]="currentBroker" [reloadBrokerBranch$]="reloadBrokerBranch$"
      (reloadBrokerOutput)="reloadBrokerOutput($event)"></app-branches>
  </section>
</mat-card>
