import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { PagingConstant } from '../shared/constants/constants';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserDetail } from '../models/user.model';
import { PaginationResponse } from '../models/pagination-response';

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  private searchStringUserList;

  getUsers(
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<UserDetail>> {
    return this.http
      .get<PaginationResponse<UserDetail>>(
        this.api.getUsersPaging(
          query,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getUsersByRole(
    roleId: string,
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<UserDetail>> {
    return this.http
      .get<PaginationResponse<UserDetail>>(
        this.api.getUsersPagingByRoleId(
          roleId,
          query,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public extractKeyContactUsers() {
    return this.api.extractKeyContactUsers();
  }

  public setSearchStringUserList(searchString) {
    this.searchStringUserList = searchString;
  }

  public getSearchStringUserList() {
    return this.searchStringUserList;
  }
}
