<div>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Search existing broker group</mat-label>
      <input formControlName="searchControl" matInput
        placeholder="Search existing broker group" (keyup.enter)="search(searchControl.value)">
        <button class="close-icon" *ngIf="!searchStringIsEmpty"  (click)="reset()" type="button"><mat-icon>clear</mat-icon></button>
      <mat-icon class="mainsearch-icon" matSuffix svgIcon="magnify" (click)="search(searchControl.value)"></mat-icon>
    </mat-form-field>
  </form>
  <div class="search-result not-found mat-elevation-z8" *ngIf="noResult"> No results found </div>
  <div class="search-result mat-elevation-z8" *ngIf="showResult">
    <mat-table class="search-result mat-elevation-z8" table [dataSource]="results">
      <div class="btn-close-search">
        <mat-icon class="btn-close-search" svgIcon="close" (click)="closeSearchResult()"></mat-icon>
      </div>

      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>Broker Group Name</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.brokerGroupName}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{selected: (selectedBrokerGroup && selectedBrokerGroup.id === row.id)}"
      (click)="onRowClick(row)">
      </mat-row>
    </mat-table>
  </div>
</div>
