<div>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Search Broker Branch Name</mat-label>
      <input formControlName="searchControl" class="form-control" matInput
        placeholder="Search Broker Branch Name" (keyup.enter)="search(searchControl.value)" autofocus/>
      <mat-icon class="close-icon" *ngIf="!searchStringIsEmpty" (click)="reset()">clear</mat-icon>
      <mat-icon class="mainsearch-icon" matSuffix svgIcon="magnify" (click)="search(searchControl.value)"></mat-icon>
    </mat-form-field>

  </form>
  <div class="search-result mat-elevation-z8" *ngIf="showResult">

    <mat-table table [dataSource]="results" *ngIf="results.length > 0">
      <div class="btn-close-search">
        <mat-icon class="btn-close-search" svgIcon="close" (click)="closeSearchResult()"></mat-icon>
      </div>

      <ng-container matColumnDef="originalBranchId">
        <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.originalBranchId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>Broker Branch Name</mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.name">{{element.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Postcode">
        <mat-header-cell *matHeaderCellDef>Postcode</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.postcode}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AddressLine1">
        <mat-header-cell *matHeaderCellDef>Address Line 1</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.address1}} </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive" class="colour-success" svgIcon="check"></mat-icon>
          <mat-icon *ngIf="!element.isActive" class="colour-danger" svgIcon="close"></mat-icon>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{selected: (selectedBranch && selectedBranch.originalBranchId === row.originalBranchId)}"
        (click)="onRowClick(row)">
      </mat-row>
    </mat-table>
    <p class="no-result" *ngIf="results.length <= 0"> No results found </p>
  </div>
</div>
