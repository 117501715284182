import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import moment from 'moment';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { FilterBrokerGroupResponse, FilterProductResponse } from 'src/app/models/claims-made-report-responses/claims-made-report-base-response';
import { FilterResponseCustomReport } from 'src/app/models/custom-pricing-report-response';
import { ProductType } from 'src/app/models/enums/product-type.enum';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { FilterResponse } from 'src/app/models/user-access-report-response';
import { ReportService } from 'src/app/services/report.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { EMPTY_GUID } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-claims-made-report',
  templateUrl: './claims-made-report.component.html',
  styleUrls: ['./claims-made-report.component.scss']
})
export class ClaimsMadeReportComponent {
  public formGroup!: FormGroup;
  allOptionTxt: string = 'All';
  actualSelectedCover: string = '';
  actualBrokerGroup: string = '';
  actualBrokerBranch: string = '';
  reportType: number = 0;
  productType = ProductType;
  currentProduct: string = '';
  currentCovers: string[] = [];

  minDateFrom: Date = new Date(1999, 0, 1);
  maxDateFrom: Date = Utility.addDays(new Date(), 60);

  minDateTo: Date = new Date(1999, 0, 1);
  maxDateTo: Date = Utility.addDays(new Date(), 60);
  products: FilterProductResponse[] = [];
  productsList: FilterResponse[] = [];
  coversList: FilterResponse[] = [];
  brokerGroupsData: FilterBrokerGroupResponse[] = [];
  brokerBranchList: FilterResponse[] = [];
  brokerGroupList: FilterResponse[] = [];
  data$ = new BehaviorSubject<any>(null);

  filteredBrokerBranchOptions: Observable<FilterResponseCustomReport[]>;
  filteredBrokerGroupOptions: Observable<FilterResponse[]>;
  brokerBranchOptions: FilterResponseCustomReport[] = [];
  selectedBranchOptions: FilterResponseCustomReport[] = [];
  brokerGroupOptions: FilterResponse[] = [];
  selectedBrokerGroup: string;
  selectedBrokerGroupName: string;
  brokerBranchValid: boolean = true;
  @ViewChild('autoCompleteInputBb', { read: MatAutocompleteTrigger }) triggerbb: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInputBbg', { read: MatAutocompleteTrigger }) triggerbbg: MatAutocompleteTrigger;
  @ViewChild('autobb') autobb: MatAutocomplete;
  @ViewChild('autobbG') autobbG: MatAutocomplete;

  public get product() {
    return this.formGroup.get('product') as AbstractControl;
  }

  public get covers() {
    return this.formGroup.get('covers') as AbstractControl;
  }

  public get brokerGroups() {
    return this.formGroup.get('brokerGroups') as AbstractControl;
  }

  public get brokerBranchs() {
    return this.formGroup.get('brokerBranchs') as AbstractControl;
  }

  public get dateFrom() {
    return this.formGroup.get('dateFrom') as AbstractControl;
  }

  public get dateTo() {
    return this.formGroup.get('dateTo') as AbstractControl;
  }

  @ViewChild('allSelectedCover') allSelected: MatOption;
  @ViewChild('matSelectCover') matSelectCover;

  get isValidSubmit() {
    return !this.formGroup.invalid;
  }

  get searchIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.brokerBranchs.value);
  }

  get searchGroupIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.brokerGroups.value);
  }

  get reachLimit() {
    return (this.selectedBranchOptions.length ?? 0) >= 20;
  }

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly httpService: HttpBaseService,
    private readonly _dialogService: DialogService,
    private readonly _api: ApiEndPoints,
  ) {

  }

  async ngOnInit(): Promise<void> {

    const defaultDateFrom = moment(new Date().setHours(0, 0, 0, 0));
    const defaultDateTo = moment(new Date().setHours(0, 0, 0, 0));

    this.formGroup = this.formBuilder.group({
      product: ['', [Validators.required]],
      covers: ['', [Validators.required]],
      brokerGroups: ['', [Validators.required]],
      brokerBranchs: ['', [Validators.required]],
      dateFrom: [defaultDateFrom, [Validators.required]],
      dateTo: [defaultDateTo, [Validators.required]],
    });

    this.products = await this._reportService.getProductClaimsMadeReport();
    this.products.forEach(element => {
      let item: FilterResponse = {
        id: element.id,
        name: element.name
      }
      this.productsList.push(item);
    });

    this.brokerGroupsData = await this._reportService.getBrokerGroupForClaimsMadeReport();
    this.brokerGroupList = [];
    this.brokerGroupsData.forEach(s => {
      let item: FilterResponse = {
        id: s.id,
        name: s.id !== EMPTY_GUID ? s.name : 'No Broker Group'
      }
      this.brokerGroupList.push(item);
    })

    let allItem: FilterResponse = {
      id: '',
      name: this.allOptionTxt
    }
    this.brokerGroupOptions = this.brokerGroupList;
    this.brokerGroupOptions.unshift(allItem);

    this.filteredBrokerGroupOptions = this.brokerGroups.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBrokerGroup(value || '', this.brokerGroupOptions)),
    );

    this.filteredBrokerBranchOptions = this.brokerBranchs.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.brokerBranchOptions)),
    );
  }

  ngAfterViewInit() {
    this.product.valueChanges.subscribe(x => this.resetTable());
    this.covers.valueChanges.subscribe(x => this.resetTable());
    this.dateFrom.valueChanges.subscribe(x => this.resetTable());
    this.dateTo.valueChanges.subscribe(x => this.resetTable());
    this.triggerbb.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        let errors = this.brokerBranchs.errors;
        if (!this.brokerBranchOptions.find(x => x.name == this.brokerBranchs.value)) {
          this.brokerBranchs.setValue('');
          this.resetAutoInput(this.autobb);
        }
        this.brokerBranchs.setErrors(errors);
      }
    });

    this.triggerbbg.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        if (this.brokerGroups.value !== this.selectedBrokerGroupName) {
          this.brokerGroups.setValue('');
          this.resetAutoInput(this.autobbG);
        }
      }
    });


  }

  async onCloseProduct() {
    if (this.product.value && this.product.value != this.currentProduct) {
      this.coversList = this.products.find(x => x.id == this.product.value)?.subProducts
      this.covers.setValue([]);
      this.currentCovers = [];
      this.currentProduct = this.product.value;
      this.resetTable();
    }
  }

  toggleSelectionCover(values: string[]) {
    let selected = (values.filter(x => !this.currentCovers.includes(x))).concat(this.currentCovers.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allSelected.selected) {
        let ids = this.coversList.map(({ id }) => id)
        this.covers.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedCover = this.allOptionTxt;
      } else {
        this.covers.setValue([]);
        this.actualSelectedCover = this.matSelectCover.triggerValue;
      }
    }
    else {
      let ids: string[] = this.covers.value;
      const index = ids.indexOf(this.allOptionTxt);
      if (index > -1) {
        ids.splice(index, 1);
        this.covers.setValue([...ids]);
      }

      if (ids.length == this.coversList.length) {
        this.covers.setValue([this.allOptionTxt,...ids]);
        this.actualSelectedCover = this.allOptionTxt;
      }
      else {
        this.actualSelectedCover = this.matSelectCover.triggerValue;
      }
    }
    this.currentCovers = this.covers?.value;
  }

  resetTable() {
    this.reportType = 0;
  }

  resetGroup() {
    this.triggerbbg.closePanel();
    this.brokerGroups.setValue('');
    this.selectedBranchOptions = [];
    this.brokerBranchOptions = [];
    this.brokerBranchs.setValue('');
    this.brokerBranchs.setErrors({ 'invalid': true });
    this.resetAutoInput(this.autobbG);
    this.resetAutoInput(this.autobb);
    this.resetTable();
  }

  onBlurBrokerBranch() {
    this.selectedBranchOptions.length <= 0 ? this.brokerBranchs.setErrors({ 'invalid': true }) : this.brokerBranchs.setErrors(null)
  }

  reset() {
    this.triggerbb.closePanel();
    this.resetAutoInput(this.autobb);
    this.brokerBranchs.setValue('');
    this.brokerBranchs.setErrors({ 'invalid': true });
    this.selectedBranchOptions.length <= 0 ? this.brokerBranchs.setErrors({ 'invalid': true }) : this.brokerBranchs.setErrors(null)
  }

  selectBrokerBranch(event: any, selectedOption: FilterResponseCustomReport) {
    if (event.isUserInput) {
      this.resetTable();
      if (this.brokerGroups.invalid) {
        this.brokerGroups.setValue(this.allOptionTxt);
        this.selectedBrokerGroup = this.allOptionTxt;
        this.selectedBrokerGroupName = this.allOptionTxt;
      }
      if (this.selectedBranchOptions.length > 19 && selectedOption.id != this.allOptionTxt) {
        return;
      }

      let index = this.selectedBranchOptions.indexOf(selectedOption);
      if (index == -1) {
        this.selectedBranchOptions.push(selectedOption);
        this.brokerBranchOptions.forEach(function (obj) {
          if (obj.id === selectedOption.id) {
            obj.isDisable = true;
          }
        })
      }

      if (selectedOption.id == this.allOptionTxt) {
        this.selectedBranchOptions = [];
        this.selectedBranchOptions.push(selectedOption);
        this.brokerBranchOptions.forEach(function (obj) {
          obj.isDisable = false;
        })

      }
      else {
        let index = this.selectedBranchOptions.findIndex(x => x.id == this.allOptionTxt);
        if (index == 0) {
          this.selectedBranchOptions.splice(index, 1);
        }
      }
      this.selectedBranchOptions.length <= 0 ? this.brokerBranchs.setErrors({ 'invalid': true }) : this.brokerBranchs.setErrors(null)
    }
  }


  async selectBrokerGroup(event: any, value: FilterResponse) {
    if (event.isUserInput) {
      this.brokerBranchOptions = [];
      this.selectedBranchOptions = [];
      this.brokerBranchs.setValue(null);
      this.selectedBrokerGroup = value.id;
      this.selectedBrokerGroupName = value.name;
      if (value.id) {
        this.brokerGroupsData.find(bg => value.id == bg.id).brokerBranchs.forEach(x => {
          let item: FilterResponseCustomReport = {
            id: x.id,
            name: x.name,
            isDisable: false
          }
          this.brokerBranchOptions.push(item);
        });
      }
      else {
        this.brokerGroupsData.forEach(x => {
          x.brokerBranchs.forEach(x => {
            let item: FilterResponseCustomReport = {
              id: x.id,
              name: x.name,
              isDisable: false
            }
            this.brokerBranchOptions.push(item);
          });
        });
      }

      if (this.brokerBranchOptions.length > 0) {
        this.brokerBranchOptions.unshift({ id: this.allOptionTxt, name: this.allOptionTxt, isDisable: false })
      }
      else {
        this.brokerBranchOptions.unshift({ id: "", name: "No associated branches for this broker group", isDisable: true })
      }
      this.brokerBranchs.setValue("", { ermitEvent: false });
      this.brokerBranchs.setErrors({ 'invalid': true });
      this.resetTable();
    }

  }

  onSubmit() {
    if (this.formGroup.valid && this.validDates()) {
      this.loadData();
    }
  }

  loadDataFromTable(data: any) {
    this.loadData(data.pageIndex, data.pageSize, data.sortField, data.sortDirection);
  }

  loadData(pageIndex = 0, pageSize = 10, sortField = '', sortDirection = 'asc') {
    let covers = this.covers.value.filter((x: string) => x != this.allOptionTxt);
    let brokerBranchs = this.selectedBranchOptions.map(a => a.id);

    this._reportService.getClaimsMadeReport(this.product.value, brokerBranchs, this.selectedBrokerGroup, covers,
      this.dateFrom.value.format('YYYY-MM-DD'), this.dateTo.value.format('YYYY-MM-DD'), sortField, sortDirection, pageIndex, pageSize)
      .subscribe((report) => {
        switch (this.getProductType()) {
          case ProductType.HomeEmergencySolutions:
            this.reportType = ProductType.HomeEmergencySolutions;
            break;
          case ProductType.ExcessProtection:
            this.reportType = ProductType.ExcessProtection;
            break;
          default:
            this.reportType = ProductType.MotorLegalExpenses;
            break;
        }
        this.data$.next(report);
      })
  }


  export(type: ExportFileType) {
    let covers = this.covers.value.filter((x: string) => x != this.allOptionTxt);
    let brokerBranchs = this.selectedBranchOptions.map(a => a.id);
    let url = this._api.exportClaimsMadeReport(this.product.value, brokerBranchs, this.selectedBrokerGroup, covers,
      this.dateFrom.value.format('YYYY-MM-DD'), this.dateTo.value.format('YYYY-MM-DD'), type);
    let fileName = '';
    switch (this.getProductType()) {
      case ProductType.MotorLegalExpenses:
        fileName = "MLEIClaimsMadeReport";
        break;
      case ProductType.ExcessProtection:
        fileName = "ExcessProtectionClaimsMadeReport";
        break;
      case ProductType.HomeEmergencySolutions:
        fileName = "HomeEmergencyClaimsMadeReport";
        break;
      case ProductType.HowdenMotorLegalExpenses:
        fileName = "HowdenMLEIClaimsMadeReport";
        break;
      default:
        fileName = "ClaimsMadeReport";
    }

    this.httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: this.getContentType(type) });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = `${fileName} ${new Date().toLocaleDateString("en-UK")}`;
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);
      });
  }

  remove(source) {
    const index = this.selectedBranchOptions.indexOf(source);
    if (index > -1) {
      this.selectedBranchOptions.splice(index, 1);
      this.brokerBranchOptions.forEach(function (obj) {
        if (obj.id === source.id) {
          obj.isDisable = false;
        }
      })
    }
    if (this.selectedBranchOptions.length <= 0) {
      this.brokerBranchs.setValue('');
      this.brokerBranchs.setErrors({ 'invalid': true })
    }
    else {
      this.brokerBranchs.setErrors(null);
    }
    this.resetAutoInput(this.autobb);
    this.resetTable();
  }

  resetAutoInput(auto: MatAutocomplete) {
    auto.options.forEach((item) => {
      item.deselect();
    });
  }

  validDates() {
    let valid = false;
    if (this.dateFrom.value && this.dateTo.value) {
      if (moment(this.dateFrom.value) > moment(this.dateTo.value)) {
        const errorMessage = 'The Date From is greater than the Date To. Please check and re-enter the dates.';
        this._dialogService.openErrorDialog({ title: 'Error', message: errorMessage });
      } else {
        valid = true;
      }
    }
    return valid;
  }

  getContentType(fileType: ExportFileType) {
    switch (fileType) {
      case ExportFileType.Pdf:
        return "application/pdf";

      case ExportFileType.Csv:
        return "text/csv";

      case ExportFileType.Excel:
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      default:
        return '';
    }
  }

  private _filterBrokerGroup(value: string, source: FilterResponse[]): FilterResponse[] {
    const filterValue = value.toLowerCase().trim();
    if (value == this.allOptionTxt) {
      return source;
    }
    let data = source.filter(option => option.name.toLowerCase().includes(filterValue)
      || option.name == this.allOptionTxt);
    return data;
  }

  private _filter(value: string, source: FilterResponseCustomReport[]): FilterResponseCustomReport[] {
    const filterValue = value.toLowerCase().trim();
    if (value == this.allOptionTxt) {
      return source;
    }
    let data = source.filter(option => option.name.toLowerCase().includes(filterValue)
      || option.name == this.allOptionTxt);
    return data;
  }

  private getProductType(): number {
    return this.products.find(x => x.id == this.product.value).type
  }

}
