<mat-card>
  <mat-card-header class="card-header">
    <mat-card-title>User Details</mat-card-title>
    <mat-card-subtitle>{{updateMode?'Update': 'Create'}} and maintain users here.</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div [formGroup]="userFormGroup">
      <section>
        <mat-form-field>
          <mat-label>User Name</mat-label>
          <input #focus [required]="true" data-test="user-username-input" matInput placeholder="User Name"
            formControlName="userName">
          <mat-error *ngIf="userName.errors?.required || !userName.errors?.whitespace &&
          !userFormGroup.get('userName').hasError('noLeadingWhitespace')">User Name is required</mat-error>
          <mat-error *ngIf="userName.errors?.maxlength">User Name can be max 250 characters long</mat-error>
          <mat-error *ngIf="userFormGroup.get('userName').hasError('noLeadingWhitespace')">Leading white spaces are not
            allowed</mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input [required]="true"  data-test="user-emailaddress-input" matInput placeholder="Email Address"
            formControlName="emailAddress" noWhiteSpaceInput>
          <mat-error *ngIf="emailAddress.errors?.required">Email Address is required</mat-error>
          <mat-error *ngIf="(emailAddress.errors?.email || emailAddress.hasError('invalidEmail')) && !emailAddress.errors?.maxlength">Email Address is invalid. Only letters (a-z, A-Z), numbers (0-9), period (.), hyphen (-), underscore (_), and plus (+)  are allowed.</mat-error>
          <mat-error *ngIf="emailAddress.errors?.maxlength">Email Address can be max 250 characters long</mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Telephone</mat-label>
          <input [required]="true" spellcheck="false" data-test="user-phonenumber-input" matInput
            placeholder="Telephone" formControlName="phoneNumber" (blur)="formatPhoneNumber()">
          <mat-error *ngIf="phoneNumber.errors && phoneNumber.errors.required">Telephone is required</mat-error>
          <mat-error *ngIf="phoneNumber.errors && phoneNumber.errors.incorrect">Invalid Telephone, please re-enter
          </mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Select role from dropdown list</mat-label>
          <mat-select data-test="user-role-select" required placeholder="Select role from dropdown list"
            formControlName="roleId">
            <mat-option *ngFor="let role of roles" [value]="role.id">
              {{role.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="roleId.errors && roleId.errors.required">Role is required</mat-error>
        </mat-form-field>

        <mat-checkbox class="check-box" formControlName="isKeyContact">Key Contact
        </mat-checkbox>
      </section>
      <section class="display-flex flex-direction-row">
        <mat-form-field>
          <mat-label>Broker Branch Name</mat-label>
          <input required readonly data-test="user-brokerbranch-input" matInput placeholder="Broker Branch Name"
            formControlName="brokerBranchName">
          <input hidden data-test="user-brokerbranch-input" matInput formControlName="brokerBranchId">

          <mat-error *ngIf="brokerBranchName.errors?.required">Broker Branch Name is required</mat-error>
        </mat-form-field>

        <app-search-broker-branch (selectedBranchOutput)="setSelectedBrokerBranch($event)">
        </app-search-broker-branch>

      </section>
      <section>
        <mat-form-field>
          <mat-label>Select status from dropdown</mat-label>
          <mat-select data-test="user-status-input" placeholder="Select status from dropdown" formControlName="status"
            required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.errors && status.errors.invalid">Status is required</mat-error>
        </mat-form-field>
      </section>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <section>
      <div>
        <button mat-raised-button color="primary" [disabled]="!userFormGroup.valid || !userFormGroup.dirty"
          (click)="save()">{{updatedUser?'Update':'Save'}}</button>
        <button mat-raised-button color="secondary" (click)="cancel()">Cancel</button>
      </div>
    </section>
  </mat-card-actions>
</mat-card>
