<div class="broker-branch-popup">
  <h3><strong>Branch Details</strong></h3>
  <h4 class="title" *ngIf="updateMode">{{updateMode?'Edit': 'Create'}} your broker branch details here</h4>
  <form [formGroup]="searchForm">
    <section *ngIf="!broker">
      <div >
        <mat-form-field *ngIf="!updateMode">
          <mat-label>Search by Broker Id, Broker Name</mat-label>
          <input matInput [formControl]="searchString" placeholder="Search by Broker Id, Broker Name"
            [matAutocomplete]="auto">
          <mat-error *ngIf="searchString.value && searchString.errors?.specialCharacter">
            {{searchString.errors?.specialCharacter?.message}}</mat-error>
          <mat-autocomplete #auto="matAutocomplete" md-menu-class="autocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.originalBrokerId"
              (onSelectionChange)="getSelectedBroker($event, option)">
              <span style="margin-right: 10px;">{{ option.originalBrokerId }}</span>
              <span>{{ option.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </section>
  </form>
  <mat-dialog-content class="mat-dialog-content">
    <div [formGroup]="branchForm">

      <section>
        <mat-form-field>
          <mat-label>Broker ID</mat-label>
          <input matInput data-test="brokerId" numberOnly placeholder="Broker ID" formControlName="brokerId" readonly
            [required]="true" maxlength="9">
          <mat-error *ngIf="brokerId.value && brokerId.errors?.specialCharacter">
            {{brokerId.errors?.specialCharacter?.message}}</mat-error>
          <mat-error *ngIf="brokerId.invalid && !brokerId.value && !brokerId.errors?.specialCharacter">Broker Id is
            required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Broker Name</mat-label>
          <input matInput data-test="brokerName" placeholder="Broker Name" formControlName="brokerName" readonly
            [required]="true">
          <mat-error *ngIf="brokerName.invalid">Broker Name is required</mat-error>
        </mat-form-field>
      </section>

      <section>
        <mat-form-field>
          <mat-label>Branch ID</mat-label>
          <input matInput data-test="branchIdInput" numberOnly placeholder="Branch ID" formControlName="branchId"
            [readonly]='updateMode' [required]="true" maxlength="9">
          <mat-error *ngIf="branchId.value && branchId.errors?.specialCharacter">
            {{branchId.errors?.specialCharacter?.message}}</mat-error>
          <mat-error *ngIf="branchId.invalid && !branchId.value && !branchId.errors?.specialCharacter">Branch Id is
            required</mat-error>
        </mat-form-field>


        <mat-form-field>
          <mat-label>Branch Name</mat-label>
          <input matInput data-test="branchNameInput" placeholder="Branch Name" formControlName="branchName" TitleCase
            [required]="true">
          <mat-error *ngIf="branchName.errors?.required || branchName.errors?.whitespace">Branch Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="select-type">
          <mat-label>Select status from dropdown list</mat-label>
          <mat-select data-test="branchStatus" placeholder="Select status from dropdown list" formControlName="status"
            required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>
      </section>

      <section>
        <mat-form-field>
          <mat-label>Select External Type from dropdown list</mat-label>
          <mat-select data-test="externalType" placeholder="Select External Type from dropdown list" formControlName="externalType">
            <mat-option [value]="emptyExternalType">N/A</mat-option>
            <mat-option *ngFor="let type of ExternalBrokerType.values()" [value]="ExternalBrokerType[type]">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>External Reference</mat-label>
          <input matInput data-test="externalReference" placeholder="External Reference" formControlName="externalReference">
           <mat-error *ngIf="externalReference?.hasError('specialCharacter') && !externalReference?.hasError('maxlength')">{{externalReference.errors?.specialCharacter?.message}}</mat-error>
           <mat-error *ngIf="externalReference?.hasError('maxlength')">{{externalReferenceMaxLenghtMessage}}</mat-error>
       </mat-form-field>
      </section>

      <app-address-info [addressForm]="branchAddress" [required]="true">
      </app-address-info>

      <section>
        <mat-form-field>
          <mat-label>Telephone</mat-label>
          <input [required]="true" spellcheck="false" data-test="phonenumberInput" matInput placeholder="Telephone"
            formControlName="phonenumber" type="tel">
          <mat-error *ngIf="phonenumber.errors && phonenumber.errors.required">Telephone number is required</mat-error>
          <mat-error *ngIf="phonenumber.errors && phonenumber.errors.invalidPhoneNumber">Invalid phone number, please
            re-enter</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input matInput data-test="emailInput" placeholder="Email Address" formControlName="email" [required]="true" noWhiteSpaceInput>
          <mat-error *ngIf="email.errors && email.errors.email || email.hasError('invalidEmail')">Invalid Email, please
            re-enter</mat-error>
          <mat-error *ngIf="email.errors && email.errors.required">Email is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Website</mat-label>
          <input matInput data-test="websiteInput" placeholder="Website" formControlName="website">
          <mat-error *ngIf="website?.errors?.urlFormat">Invalid url format entered. Please retry.</mat-error>
        </mat-form-field>
      </section>
      <section class="key-contacts">
        <h4>Key Contacts</h4>
        <app-contact-detail [keyContact]="keyContact.Finance" #contactDetailFormComponent1>
        </app-contact-detail>
        <app-contact-detail [keyContact]="keyContact.Compliance" #contactDetailFormComponent2>
        </app-contact-detail>
        <app-contact-detail [keyContact]="keyContact.ProductUpdate" #contactDetailFormComponent3>
        </app-contact-detail>
        <app-contact-detail keyContact="Contact #1" #contactDetailFormComponent4>
        </app-contact-detail>
        <app-contact-detail keyContact="Contact #2" #contactDetailFormComponent5>
        </app-contact-detail>
      </section>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-items-end button-row">
    <button mat-button mat-raised-button color="primary" [disabled]="getValidity()"
      (click)="onSave()">{{updateMode?'Update': 'Save'}}</button>
    <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(false)">Cancel</button>
  </mat-dialog-actions>
</div>
