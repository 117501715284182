import { Directive, HostListener} from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[noWhiteSpaceInput]",
})
export class NoWhiteSpaceInputDirecive {
  constructor(private readonly ngControl: NgControl) { }
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    let original = this.ngControl.value as string;
    this.ngControl.control.setValue(original.replace(/\s+/g, ''), { emitEvent: false, onlySelf: true });
    this.ngControl.control.updateValueAndValidity();
  }

}
