<div class="not-found" *ngIf="notFoundMessage">{{notFoundMessage}}</div>

<mat-card [class]='sourceDialog ? "diaglog-table" : "table"' [style]="getTableStyle()">
  <mat-table [dataSource]="dataSource" matSort matSortActive="inceptionDate" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="certificateNumber">
      <mat-header-cell *matHeaderCellDef> Certificate Number </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis"> {{row.certificateNumber}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="schemeNumber">
      <mat-header-cell *matHeaderCellDef> Scheme Number </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis"> {{row.schemeNumber}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inceptionDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Inception Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis"> {{row.inceptionDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expiryDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.expiryDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="policyHolder">
      <mat-header-cell *matHeaderCellDef> Policy Holder </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.policyHolder}}>{{row.policyHolder}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.address}}>{{row.address}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="postcode">
      <mat-header-cell *matHeaderCellDef> Postcode </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.postcode}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="country">
      <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.country}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="doB">
      <mat-header-cell *matHeaderCellDef> Date of Birth </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.doB | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionDate">
      <mat-header-cell *matHeaderCellDef> Transaction Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.transactionDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionType">
      <mat-header-cell *matHeaderCellDef> Transaction Type </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.transactionType}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cancellationDate">
      <mat-header-cell *matHeaderCellDef> Cancellation Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.cancellationDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="netRate">
      <mat-header-cell *matHeaderCellDef> Net Rate </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.netRate | currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalIPT">
      <mat-header-cell *matHeaderCellDef> Total IPT </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.totalIPT | currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalAmountPayable">
      <mat-header-cell *matHeaderCellDef> Total Amount Payable </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.totalAmountPayable | currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="grossPremium">
      <mat-header-cell *matHeaderCellDef> Gross Premium </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.grossPremium |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple
      [ngClass]="row.isHaveNegativeLine ? row.transactionType == transactionType.CANX ? 'canx-row': 'new-row':''"
      *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div class="display-flex flex-direction-row footer">
    <div class="btn-group flex-50">
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to Excel" mat-raised-button color="primary" (click)="exportExcel()">
        Export to Excel
      </button>
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to CSV" mat-raised-button color="primary" (click)="exportCsv()">
        Export to CSV
      </button>
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to PDF" mat-raised-button color="primary" (click)="exportPdf()">
        Export to PDF
      </button>
    </div>

    <div class="paging flex-50">
      <mat-paginator [length]="totalRows" [pageIndex]="pagingConstant.pageIndex"
        [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</mat-card>
